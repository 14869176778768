






































































































































































































































.inner_app {
  height: 100%;
}
::v-deep {
  .section_comm {
    box-sizing: border-box;
    width: 100%;
    min-width: auto;
    max-width: 2400px;
    margin: 0 auto;
    padding: 0 20px 64px;
  }
}

.window-popup {
  // 팝업으로 열렸을시 숨김 처리 한다
  &::v-deep #kakaoHead,
  &::v-deep .wrap_btn.type_bottom,
  &::v-deep .section_sticky {
    display: none;
  }
  &::v-deep .cont_sub {
    padding: 0;
  }
  &::v-deep #mFeature {
    top: 0;
    padding-top: 30px;
  }
  &::v-deep .box_gnb:first-child {
    margin-top: 0;
  }
}
#toastWrap {
  position: fixed;
  left: 0;
  width: 100vw;
  bottom: 0;
  padding-bottom: 30px;
  box-sizing: border-box;
  pointer-events: none;
  z-index: 1000;
}
