































































































































































.k_head {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  min-width: 1240px;
  height: 70px;
  background-color: #222;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
.k_head .tit_logo {
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  height: inherit;
}
.k_head .tit_logo .link_logo {
  display: block;
  padding: 0 32px;
}
.k_head .tit_logo .img_g {
  width: 207px;
}
.k_head .menu_profile {
  float: right;
  position: relative;
  display: flex;
  flex-flow: column;
  height: inherit;
}
.k_head .menu_profile .info_tel {
  flex: 0;
  padding: 3px 15px;
  color: #ffffff;
}
.k_head .menu_profile .btn_profile {
  flex: 1;
  display: block;
  padding: 0 15px;
  font-size: 12px;
  color: #bbb;
  outline: none;
}
.k_head .menu_profile .link_login {
  display: block;
  height: 70px;
  padding: 0 32px;
  font-size: 12px;
  line-height: 70px;
  color: #bbb;
}
.k_head .menu_profile .btn_profile .ico_arr_profile {
  margin: 8px 0 0 7px;
}
.btn_profile .ico_arr_profile {
  background-position: 0 -10px;
}
.k_head .menu_profile .box_profile {
  display: none;
  position: absolute;
  top: 56px;
  right: 24px;
  z-index: 110;
  width: 130px;
  padding: 16px 0;
  border-radius: 2px;
  border: 1px solid #222;
  background-color: #fff;
}
.k_head .menu_profile .box_profile .link_profile {
  display: block;
  padding: 0 24px;
  font-size: 12px;
  line-height: 18px;
  color: #555;
}
.k_head .menu_profile .box_profile .link_profile:hover {
  text-decoration: underline;
}
.k_head .menu_profile .box_profile .link_profile + .link_profile {
  margin-top: 8px;
}
.k_head .menu_profile.on .box_profile {
  display: block;
}

/* -- gnb -- */
.gnb_purchase {
  margin-left: 294px;
  overflow-x: auto;
}
.gnb_purchase .list_gnb {
  display: block;
  white-space: nowrap;
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}
